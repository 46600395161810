<template>
  <div class="theme">
    <vs-row>
      <vs-col vs-lg="8" vs-xs="12">
        <vs-card>
          <div slot="header">
            <h3>{{ lang.themes.form.colors.title[languageSelected] }}</h3>
          </div>
          <vs-row>
            <vs-col vs-w="12">
              <div
                v-for="(color, name) in theme.colors"
                :key="name"
                class="theme__color-input"
              >
                <color-picker
                  @input="changeColor($event, name)"
                  :color="theme.colors[name]"
                  :label="name"
                  v-model="theme.colors[name]"
                  class="color-picker"
                />
                <div class="description">
                  <feather-icon icon="InfoIcon"></feather-icon>
                  <span class="text">{{ colorDescription(name) }}</span>
                </div>
              </div>
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col class="buttons">
              <vs-button
                icon-pack="feather"
                icon="icon-settings"
                type="filled"
                @click="setDefaultTheme()"
              >
                {{ lang.themes.form.common.default[languageSelected] }}
              </vs-button>
            </vs-col>
          </vs-row>
        </vs-card>
      </vs-col>
      <vs-col vs-lg="4" vs-xs="12">
        <vs-row>
          <vs-card>
            <div slot="header">
              <h3>{{ lang.themes.form.theme.title[languageSelected] }}</h3>
            </div>
            <div>
              <ul class="leftx">
                <li v-for="t in themes" :key="t">
                  <vs-radio
                    v-model="theme.name"
                    vs-name="theme"
                    :vs-value="t"
                    @change="updateTheme(theme.name)"
                  >
                    {{ t }}
                  </vs-radio>
                </li>
              </ul>
            </div>
          </vs-card>
        </vs-row>
        <vs-row>
          <vs-card>
            <div slot="header">
              <h3>
                {{ lang.themes.form.companyLogo.title[languageSelected] }}
              </h3>
            </div>
            <div>
              <vs-input
                class="w-full"
                :label="lang.themes.form.companyLogo.label[languageSelected]"
                v-model="theme.companyLogo"
                :danger="logoError"
                :danger-text="
                  lang.themes.form.companyLogo.error[languageSelected]
                "
              />
              <div class="buttons">
                <vs-button
                  icon-pack="feather"
                  icon="icon-eye"
                  type="filled"
                  @click="setCompanyLogo(theme.companyLogo)"
                >
                  {{ lang.themes.form.companyLogo.preview[languageSelected] }}
                </vs-button>
                <vs-button
                  icon-pack="feather"
                  icon="icon-refresh-cw"
                  type="filled"
                  @click="setLogo(currentCompanyLogo)"
                >
                  {{ lang.themes.form.common.reset[languageSelected] }}
                </vs-button>
              </div>
            </div>
          </vs-card>
        </vs-row>
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col class="flex items-center justify-center">
        <vs-button color="danger" type="flat" @click="cancel()" class="mr-4">
          {{ lang.themes.form.common.cancel[languageSelected] }}
        </vs-button>
        <vs-button @click="saveTheme()">
          {{ lang.themes.form.common.save[languageSelected] }}
        </vs-button>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { themeConfig, themeColors, themes } from '../../../../../themeConfig.js'
import StorageService from '../../../../services/storage.service'

export default {
  name: 'Themes',
  data() {
    return {
      currentCompanyLogo: '',
      theme: {
        name: this.$store.state.theme,
        companyLogo: this.$store.state.companyLogo,
        colors: this.$store.state.themeColors
      },
      logoError: false,
      themes,
      currentTheme: null
    }
  },
  components: {
    ColorPicker: () => import('./ColorPicker.vue')
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  },
  methods: {
    ...mapActions(['updateTheme', 'setCompanyLogo', 'saveCompanyTheme']),
    changeColor(value, name) {
      this.theme.colors[name] = value
      this.$vs.theme(this.theme.colors)
    },
    setLogo(url) {
      this.logoError = false
      this.setCompanyLogo(url)
      this.theme.companyLogo = url
    },
    setDefaultTheme() {
      this.theme = {
        companyLogo: this.$store.state.companyLogo,
        name: themeConfig.theme,
        colors: Object.assign({}, this.theme.colors, themeColors)
      }
      this.updateTheme(this.theme.name)
      this.$vs.theme(themeColors)
    },
    saveTheme() {
      this.$vs.loading()
      if (this.theme && this.theme.companyLogo === null) {
        this.theme.companyLogo = ''
      }
      this.saveCompanyTheme(this.theme)
        .then(() => {
          StorageService.update('session', 'theme', this.theme)
          this.currentCompanyLogo = this.theme.companyLogo
          this.$vs.notify({
            color: 'success',
            title: this.lang.themes.save.success.title[this.languageSelected],
            text: this.lang.themes.save.success.text[this.languageSelected]
          })
          this.logoError = false
        })
        .catch(error => {
          this.$vs.notify({
            color: 'danger',
            title: this.lang.themes.save.error.title[this.languageSelected],
            text: this.lang.themes.save.error.text[this.languageSelected]
          })
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.length > 0
          ) {
            let logoField = error.response.data.find(
              d => d.field === 'data.theme.companyLogo'
            )
            if (logoField) {
              this.logoError = true
            }
          }
          this.$log.error(error)
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    cancel() {
      this.theme.name = this.themes[2]
      this.theme.companyLogo = ''
      this.theme.colors = Object.assign({}, this.currentTheme)
      this.updateTheme(this.theme.name)
      this.setLogo(this.currentCompanyLogo)
      this.$vs.theme(this.theme.colors)
    },
    colorDescription(colorName) {
      return this.lang.themes.form.colors[colorName][this.languageSelected]
    }
  },
  mounted() {
    this.currentTheme = Object.assign({}, this.theme.colors)
    this.currentCompanyLogo = this.$store.state.companyLogo
  }
}
</script>

<style lang="scss">
.theme {
  .buttons {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
    .vs-button {
      margin-left: 10px;
    }
  }
  &__select {
    .con-select {
      width: auto;
    }
  }
  &__color-input {
    display: flex;
    flex-basis: 50%;
    position: relative;
    margin-top: 10px;
    .color-picker {
      display: flex;
      flex-basis: 50%;
    }
    label {
      text-transform: capitalize;
    }
    .description {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-top: 15px;
      padding-left: 20px;
      .text {
        margin-left: 10px;
      }
    }
  }
}
</style>
